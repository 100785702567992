/**
 * Created by petermoeller on 28/10/15.
 */
"use strict"
// console.log("punchclock.js");
var React = require('react');
var ReactDOM = require('react-dom');
var KeyPad = require('./keypad');
var Api = require('../common/api');
var moment = require('moment-timezone');
var options = require('../common/options');
var createReactClass = require('create-react-class');
var PropTypes = require('prop-types');

var Avatar = require('../common/avatar');  // When using new style classes

class PunchClockButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {btnLoading: null};
        this.setState = this.setState.bind(this);
    }

    static defaultProps = {};

    clickButton(e) {
        e.preventDefault();
        //this.props.action(e, this.props);
        this.setState({btnLoading: true});
        this.props.action(this, (status) => {
            this.setState({btnLoading: status});
        });

    }

    render() {
        let loading = (!!this.state.btnLoading);

        let btnClass = "punchBtn";
        if (!!this.props.colour) {
            btnClass += " "+ this.props.colour;
        }

        if (loading) {
            // Button is waiting for response from server!
            let btnLabel = gettext("Vent venligst...");
            btnClass += " waiting";

            return (
                <span className={btnClass}>
                  <span className="btnText">{btnLabel}</span>
                </span>
            );
        }

        // Normal button!
        return (
            <span onTouchTap={this.clickButton.bind(this)} className={btnClass}>
              <span className="btnText">{this.props.label}</span>
            </span>
        );
    }
}


var LoginView = createReactClass({
  contextTypes: {
      // Get context from App component. Timezone for the account
      punchclockEmployee:  PropTypes.object
  },
  getInitialState: function(){
    // this.props er initial data sendt med fra 'parent'...
    return {pinCodeRequested: null};
  },

  getRequestPinCodeTxt: function () {
    var employee = this.context.punchclockEmployee;
    if (employee.user && employee.user.phone) {
      return gettext("Klik her for at modtage din pinkode på sms & email");
    }
    return gettext("Klik her for at modtage din pinkode på email");
  },

  render: function() {
    var pincode_text = this.getRequestPinCodeTxt()

    return (
      <div className="col-xs-12 pincodeWrap">
        <div className="nameHeader titel">{this.props.label}</div>
        <KeyPad.Main submitOnChars="4" onSubmit={this.props.onSubmitPin} onCancel={this.props.cancelLoginCallback} />
        <span href="#" onTouchTap={this.props.requestPin} className="textLink">{pincode_text}</span>
      </div>
    );
  }
});


var Shift = createReactClass({
  contextTypes: {
      // Get context from App component. Timezone for the account
      appLoadTerminal: PropTypes.func,
      appSettings: PropTypes.object,
      punchclockUserToken: PropTypes.string,
      punchclockInvokeClockOut: PropTypes.func,
      punchclockRaiseInvalidUserToken: PropTypes.func
  },
  getInitialState: function(){
    // this.props er initial data sendt med fra 'parent'...
    return {
        data: this.props.data
    };
  },

  getStatus: function(){
    // checks this.state.data.events or localtime or type
    var start_dt = moment(this.state.data.start_dt.iso_8601, moment.ISO_8601);
    var account_now =  moment().tz(this.context.appSettings.tz);

    var events_reversed = this.state.data.events.slice().reverse();
    var latest_event = events_reversed[0];

    // Set status
    var status = 'scheduled';
    if (this.state.data.type === 'worklog') {
      if (latest_event) {
        switch (latest_event.event_type) {
          case "clock_in":
              status = "clocked_in";
              break;
          case "clock_out":
              status = "clocked_out";
              break;
          case "break_start":
              status = "break";
              break;
          case "break_done":
              status = "clocked_in";
              break;
        }
      } else if (account_now.isAfter(start_dt)) {
        var status = 'exceeded';
      }
    } else if (this.state.data.type === 'shift' && account_now.isAfter(start_dt)) {
        var status = 'exceeded';
    }
    return status;
  },

  getClockedInDt: function () {
    for (var i = 0, j = this.state.data.events.length; i < j; i += 1) {
      var event = this.state.data.events[i];
      if (event.event_type === 'clock_in') {
        return event.event_datetime.datetime_formatted;
      }
    }
    return;
  },
  getClockedOutDt: function () {
    for (var i = 0, j = this.state.data.events.length; i < j; i += 1) {
      var event = this.state.data.events[i];
      if (event.event_type === 'clock_out') {
        return event.event_datetime.datetime_formatted;
      }
    }
    return;
  },
  getBreakStartMoment: function () {
    var events_reversed = this.state.data.events.slice().reverse();
    for (var i = 0, j = events_reversed.length; i < j; i += 1) {
      var event = events_reversed[i];
      if (event.event_type === 'break_start') {
        return moment(event.event_datetime.iso_8601, moment.ISO_8601);
      }
    }
  },
  setStatus: function () {
    var status = this.getStatus();
    this.setState({'status': status});
  },
  componentWillUnmount: function() {
    if(this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = undefined;
    }
  },

  // Event methods:
  handleBtnClick(btn) {
      btn.props.action();
  },

  getMetadata() {
    const userAgent = window.navigator.userAgent
    let environment = 'terminal'

    const isMobile = /Mobi/i.test(userAgent)
    const isIOS = ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    let platform = isMobile ? (isIOS ? 'ios' : 'android') : 'desktop'

    let browser = 'other'
    if (userAgent.match(/brave/i)) browser = 'brave'
    else if (userAgent.match(/duckduck/i)) browser = 'duckduckgo'
    else if (userAgent.match(/ucbrowser/i)) browser = 'ucbrowser'
    else if (userAgent.match(/samsungbrowser/i)) browser = 'samsungbrowser'
    else if (userAgent.match(/miuibrowser/i)) browser = 'miuibrowser'
    else if (userAgent.match(/edg|edge/i)) browser = 'edge'
    else if (userAgent.match(/opr\/|opt\/|opera/i)) browser = 'opera'
    else if (userAgent.match(/firefox|fxios/i)) browser = 'firefox'
    else if (userAgent.match(/chrome|chromium|crios/i)) browser = 'chrome'
    else if (userAgent.match(/safari/i)) browser = 'safari'

    let meta = platform + '_' + environment
    if (environment != 'native')
      meta += '_' + browser

    meta += '_' + 'punchclock'

    return meta
  },

  clockIn: function(buttonClicked) {
    //buttonClicked.preventDefault();
    var end_point = null;
    if (this.state.data.type === 'shift') {
      end_point = 'punchclock/shifts/' + this.state.data.id + "/clock_in/"
    } else {
      end_point = "punchclock/worklogs/" + this.state.data.id + "/clock_in/";
    }
    var apiDispatchCallback = function(jqXHR) {

      if (jqXHR.statusCode === 200) {
          this.context.appLoadTerminal({confirmation: gettext('Du er nu stemplet ind.')});
      }
      else if ([401].indexOf(jqXHR.statusCode) >= 0) {
        this.context.punchclockRaiseInvalidUserToken({msg: gettext("Ugyldig pinkode, prøv venligst igen.")});
      }
      else {
        var errMsg = jqXHR.body.detail ? jqXHR.body.detail : gettext("Der skete en fejl. Prøv venligst igen.");
        alert(errMsg);
        buttonClicked.setState({loading: false});
      }
    }.bind(this);

    var data = { "platform": this.getMetadata() }
    Api.Dispatcher({endPoint: end_point, method: 'post', data: data, headers: {'Authorization': "token " + this.context.punchclockUserToken}}, apiDispatchCallback)
  },

  clockOut: function(e) {

    this.context.punchclockInvokeClockOut(this.state.data);
  },

  breakOn: function(buttonClicked) {
    //e.preventDefault();
    var end_point = "punchclock/worklogs/" + this.state.data.id + "/break_start/";
    var apiDispatchCallback = function(jqXHR) {
      if (jqXHR.statusCode === 200) {
        this.context.appLoadTerminal({confirmation: gettext('Du er nu på pause')});
      }
      else if ([401].indexOf(jqXHR.statusCode) >= 0) {
        this.context.punchclockRaiseInvalidUserToken({msg: gettext("Ugyldig pinkode, log venligst ind igen.")});
      }
      else {
        var errMsg = jqXHR.body.detail ? jqXHR.body.detail : gettext("Der skete en fejl. Prøv venligst igen.");
        alert(errMsg);
        buttonClicked.setState({loading: false});
      }
    }.bind(this);
    Api.Dispatcher({endPoint: end_point, method: 'post', data: "", headers: {'Authorization': "token " + this.context.punchclockUserToken}}, apiDispatchCallback)
  },

  breakOff: function(buttonClicked) {
    //e.preventDefault();
    var end_point = "punchclock/worklogs/" + this.state.data.id + "/break_done/";
    var apiDispatchCallback = function(jqXHR) {

      if (jqXHR.statusCode === 200) {
        this.context.appLoadTerminal({confirmation: gettext('Du er nu tilbage fra pause.')});
      }
      else if ([401].indexOf(jqXHR.statusCode) >= 0) {
        this.context.punchclockRaiseInvalidUserToken({msg: gettext("Ugyldig pinkode, log venligst ind igen.")});
      }
      else {
        var errMsg = jqXHR.body.detail ? jqXHR.body.detail : gettext("Der skete en fejl. Prøv venligst igen.");
        alert(errMsg);
        buttonClicked.setState({loading: false});
      }
    }.bind(this);
    Api.Dispatcher({endPoint: end_point, method: 'post', data: "", headers: {'Authorization': "token " + this.context.punchclockUserToken}}, apiDispatchCallback)
  },

  // Render method
  renderScheduled: function(){
    let btnLabel = gettext("Stempel ind");
    const {data: { start_dt, end_dt }} = this.props;
    return (
      <div className="whiteConBox">
        <p>{this.state.data.job_type.title} {gettext("vagt")}</p>
        <p className="sub">{start_dt.datetime_formatted} - {end_dt.datetime_formatted}</p>
        <div className="punchBtnWrap">
          <PunchClockButton
            action={this.clockIn}
            label={btnLabel}
            btnID={'clockIn'}
            handleBtnClick={this.handleBtnClick}
          />
        </div>
      </div>
    );
  },
  renderExceeded: function(){
    let btnLabel = gettext("Stempel ind");
    const {data: {start_dt, end_dt}} = this.props;
    return (
      <div className="whiteConBox">
        <p>{this.state.data.job_type.title} {gettext("vagt")}</p>
        <p className="sub">{start_dt.datetime_formatted} - {end_dt.datetime_formatted}</p>
        <div className="punchBtnWrap">
          <PunchClockButton
            action={this.clockIn}
            label={btnLabel}
            btnID={'clockIn'}
            handleBtnClick={this.handleBtnClick}
          />
        </div>
      </div>
    );
  },
  renderClockedIn: function(){
    let clockOutBtnLabel = gettext("Stempel ud");
    let breakOnBtnLabel = gettext("Gå til pause");

    /// Check if app supports going to break during work...
    if (this.context.appSettings.breakSupport === 'during') {
      return (
        <div className="whiteConBox">
          <p>{this.state.data.job_type.title} {gettext("vagt")}</p>
          <p className="sub">{gettext("Du stemplede ind:")} {this.getClockedInDt()}</p>
          <div className="punchBtnWrap">
            <PunchClockButton
              action={this.clockOut}
              label={clockOutBtnLabel}
              colour={'red'}
              btnID={'clockOut'}
              handleBtnClick={this.handleBtnClick}
            />
            <PunchClockButton
              action={this.breakOn}
              label={breakOnBtnLabel}
              colour={'orange'}
              btnID={'breakOn'}
              handleBtnClick={this.handleBtnClick}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="whiteConBox">
          <p>{this.state.data.job_type.title} {gettext("vagt")}</p>
          <p className="sub">{gettext("Du stemplede ind:")} {this.getClockedInDt()}</p>
          <div className="punchBtnWrap">
          <PunchClockButton
            action={this.clockOut}
            btnID={'clockOut'}
            colour={'red'}
            label={clockOutBtnLabel}
            handleBtnClick={this.handleBtnClick}
          />
          </div>
        </div>
      );
    }
  },
  renderClockedOut: function(){
    let fmts = gettext("Vagten er slut. Stempelursdata: %(clocked_in)s - %(clocked_out)s");
    let clocked_in = this.getClockedInDt();
    let clocked_out = this.getClockedOutDt();
    let label = interpolate(fmts, {clocked_in: clocked_in, clocked_out: clocked_out}, true);
    return (
      <div className="whiteConBox">
        <p>{this.state.data.job_type.title} {gettext("vagt")}</p>
        <p className="sub">{label}</p>
      </div>
    );
  },


  renderClockedInBreak: function(){
    //  Set default time zone.
    moment.tz.setDefault(this.context.appSettings.tz);
    let breakStartMoment = this.getBreakStartMoment();
    let accountNow =  moment();
    let breakDurationMins = accountNow.diff(breakStartMoment, 'minutes');
    let fmts = gettext("Du har været på pause i %(dur)s min...");
    let label = interpolate(fmts, {dur: breakDurationMins}, true);
    let btnLabel = gettext("Tilbage fra pause");
    return (
      <div className="whiteConBox">
        <p>{this.state.data.job_type.title} {gettext("vagt")}</p>
        <p className="sub">{label}</p>
        <div className="punchBtnWrap">
          <PunchClockButton
            action={this.breakOff}
            colour={'orange'}
            btnID={"breakOff"}
            label={btnLabel}
            handleBtnClick={this.handleBtnClick}
          />
        </div>
      </div>
    );
  },

  // check status and render accordingly
  render: function() {
      // console.log("Shift render", this.context, this.props);
    let shift_status = this.getStatus();
    if (shift_status === 'scheduled') {
      return this.renderScheduled();
    }
    else if (shift_status === 'exceeded') {
      return this.renderExceeded();
    }
    else if (shift_status === 'clocked_in'){
      return this.renderClockedIn();
    }
    else if (shift_status === 'clocked_out'){
      return this.renderClockedOut();
    }
    else if (shift_status === 'break'){
      return this.renderClockedInBreak();
    }
  }
});



var ShiftsView = createReactClass({
  contextTypes: {
      // Get context from PunchClock component. Timezone for the account
      punchclockUserToken: PropTypes.string,
      punchclockEmployee: PropTypes.object,
      punchclockRaiseInvalidUserToken: PropTypes.func
  },
  getInitialState: function() {
    return {shifts: [], intervalId: null};
  },

  loadDataFromServer: function () {
    // TODO: catch errors!
    var apiDispatchCallback = function(jqXHR) {
      if (jqXHR.statusCode === 200)  {
        var shifts = jqXHR.body.shifts;
        this.setState({shifts: shifts});
      }
      else if ([401].indexOf(jqXHR.statusCode) >= 0) {
        this.context.punchclockRaiseInvalidUserToken({msg: gettext("Ugyldig pinkode, log venligst ind igen.")});
      }
      else {
        var errMsg = jqXHR.body.detail ? jqXHR.body.detail : gettext("Der skete en fejl. Prøv venligst igen.");
        alert(errMsg);
      }
    }.bind(this);
    Api.Dispatcher({endPoint: 'punchclock/'+this.context.punchclockEmployee.id+"/", method: 'get', headers: {'Authorization': "token " + this.context.punchclockUserToken}}, apiDispatchCallback)
  },

  componentDidMount: function() {
    // load shifts from server and setInterval for doing this later on.
    // TODO: move setInterval stuff into a combined websockets/interval-set-clear method.
    this.loadDataFromServer();
    var intervalId = setInterval(this.loadDataFromServer, 15000);
    this.setState({intervalId: intervalId});
  },

  componentWillUnmount: function() {
    // clearInterval when unmounted... no more need for fetching shifts.
    if (this.state.intervalId) {
      clearInterval(this.state.intervalId);
    }
  },

  render() {
      return <ShiftList shifts={this.state.shifts} key={this.state.intervalId} />;
  }
});


var ShiftList = createReactClass({
  contextTypes: {
      // Get context from App component. Timezone for the account
      punchclockInvokeUnscheduledClockIn: PropTypes.func,
      appLoadTerminal: PropTypes.func
  },
  handleClick: function () {
    this.context.punchclockInvokeUnscheduledClockIn();
  },
  render: function() {
    var renderedshifts = this.props.shifts.map(function(shift) {
      return (
        <Shift data={shift} key={shift.id}/>
      );
    });
    return (
      <div>
          <p>{this.props.label}</p>
          <div>
          {renderedshifts}
          </div>
          <div className="titel">{gettext("Har du ikke en vagt at stemple ind på?")}</div>
          <div onTouchTap={this.handleClick} className="whiteConBox noShift">
          <p>{gettext("Opret vagt og stempel ind")}</p>
        </div>
      </div>
    );
  }
});


var Jobtype = createReactClass({
  getInitialState: function(){
    // this.props er initial data sendt med fra 'parent'...
    return {data: this.props.data};
  },
  handleClick: function () {
    this.props.onSelectJobtype(this.state.data);
  },
  render: function() {
    return(
      <div className="jobtype smallList" onTouchTap={this.handleClick}>
        <p>{this.state.data.title}</p>
      </div>
    )
  }
});

var Location = createReactClass({
  getInitialState: function(){
    // this.props er initial data sendt med fra 'parent'...
    return {data: this.props.data};
  },
  handleClick: function () {
    this.props.onSelectLocation(this.state.data);
  },
  render: function() {
    // Dynamic string formatting
    console.log("Location", this.props.data.title);
    //var fmts = gettext("Vagtplan fra %(from)s til %(to)s");
    var label = this.props.data.title
    //if (this.state.data.name) {
    //     var label = `${this.state.data.name} (${this.state.data.start_date.iso_8601_reversed} - ${this.state.data.end_date.iso_8601_reversed})`;
    //} else {
    //    var label = interpolate(fmts, {from: this.state.data.start_date.iso_8601_reversed, to: this.state.data.end_date.iso_8601_reversed}, true);
    //}
    return(
      <div className="smallList" onTouchTap={this.handleClick}>
        <p className="locationWrap">
          <span className="location-icon"></span> {label}
        </p>
      </div>
    )
  }
});


var ClockInSceneJobtype = createReactClass({
  getInitialState: function () {
    // this.props er initial data sendt med fra 'parent'...
    return {allJobtypes: this.props.allJobtypes, employeeJobtypes: this.props.employeeJobtypes};
  },
  render: function() {
    var selectJobtypeCallBack = this.props.handleJobtypeSelected;
    var employee_jobtypes = this.state.employeeJobtypes.map(function(jobtype) {
      return (<Jobtype key={jobtype.id} data={jobtype} onSelectJobtype={selectJobtypeCallBack}/>);
    });
    var all_jobtypes = this.state.allJobtypes.map(function(jobtype) {
      return (<Jobtype key={jobtype.id} data={jobtype} onSelectJobtype={selectJobtypeCallBack}/>);
    });

    return (
      <div>
        <div className="titel">{gettext("Mine jobtyper")}</div>
        <div className="whiteConBox">
          {employee_jobtypes}
        </div>
        <div className="titel">{gettext("Alle jobtyper")}</div>
        <div className="whiteConBox">
          {all_jobtypes}
        </div>
      </div>
    );
  }
});


var ClockInSceneLocation = createReactClass({
  getInitialState: function(){
    // this.props er initial data sendt med fra 'parent'...
    return {locations: this.props.locations};
  },
  render: function() {
    var selectLocationCallBack = this.props.handleLocationSelected;
    var rendered_locations = this.state.locations.map(function(location) {
      return (<Location key={location.id} data={location} onSelectLocation={selectLocationCallBack}/>);
    });
    return (
      <div>
        <div className='whiteConBox'>
          {rendered_locations}
        </div>
      </div>
    );
  }
});


var ClockInSceneEndTime = createReactClass({
  handleClick: function (btnContext, callback) {
    //var value = this.refs.endtimeInput.getDOMNode().value;
    let value = this.refs.endtimeInput.value;
    let parsedTime = moment(value, ["Hmm", "HHmm", "H:mm", "HH:mm"]).format("HH:mm");
    this.props.handleEndtimeSelected(parsedTime, callback);
  },
  componentDidMount(){
      ReactDOM.findDOMNode(this.refs.endtimeInput).focus();
  },
  render: function() {
    let btnLabel = gettext("Bekræft sluttidspunkt");
    return (
      <div className="endtime endTimeCon whiteConBox center">
        <input
          type='time'
          ref='endtimeInput'
          placeholder='TT:MM'
          autoFocus
          /* binding the input value to state */
          className="endtime endTimeInput"
        />
        <PunchClockButton
          action={this.handleClick}
          btnID={"confirmEnd"}
          colour={"blue"}
          label={btnLabel}
          handleBtnClick={this.handleBtnClick}
        />
      </div>
    );
  }
});



var ClockInSceneConfirm = createReactClass({
  contextTypes: {
      // Get context from App component. Timezone for the account
      appSettings: PropTypes.object
  },
  getInitialState: function() {
    const valid_formats = ["HH:mm", "HHmm", "HH"];
    let account_now = moment().tz(this.context.appSettings.tz);
    let user_time_format = this.context.appSettings.user_time_format;
    let end_time_format = this.props.end_time ? moment(this.props.end_time, valid_formats, true).format(user_time_format) : gettext('??:??');

    return {
      location: this.props.location,
      jobtype: this.props.jobtype,
      start_time: account_now.format(user_time_format),
      end_time: end_time_format
    };
  },
  render: function() {
    let location = this.state.location.title;
    let btnLabel = gettext("Stempel ind");

    return (
      <div className="confirm whiteConBox">
        <div>
          <p>{this.state.jobtype.title} {gettext("vagt")}</p>
          <p className="sub subIcon">
            <span className="timeWrap">
              <span className="clock-icon"></span>
              {this.state.start_time} - {this.state.end_time}
            </span>
            <span className="locationWrap">
              <span className="location-icon"></span>
              {location}
            </span>
          </p>
        </div>
        <PunchClockButton
          action={this.props.handleConfirmation}
          btnID={"clockIn"}
          label={btnLabel}
          handleBtnClick={this.handleBtnClick}
        />
      </div>
    );
  }
});



var UnscheduledClockInView = createReactClass({
  /*
   * Component for the form/view when punching in for a new unschedule shift.
   * fetches form data from the backend (jobtypes, shiftplans etc.)
   * also handles submit and error handling through the API.
   * */
  // INITIAL STUFF
  contextTypes: {
    punchclockUserToken: PropTypes.string,
    punchclockEmployee: PropTypes.object,
    punchclockRaiseInvalidUserToken: PropTypes.func,
    appAccountLocationUUID: PropTypes.string,
    appLoadTerminal: PropTypes.func,
    appSettings: PropTypes.object
  },
  getInitialState: function() {
    return {
      employeeJobtypes: [],
      allJobtypes: [],
      locations: [],
      selected_jobtype: null,
      selected_location: null,
      selected_endtime: null,
      errMsg: "",
      data_loaded: false,
      intervalId: null
   };
 },
  // DATA/UPDATING STUFF
  loadDataFromServer: function() {
    var apiDispatchCallback = function(jqXHR) {
      if (jqXHR.statusCode === 200) {
        var allJobtypes = jqXHR.body.allJobtypes;
        var employeeJobtypes = jqXHR.body.employeeJobtypes;
        var locations = jqXHR.body.locations;
        var selected_location = this.state.selected_location;
        var errMsg = "";

        // Automatically select location based on appAccountLocationUUID if matching
        var matchingLocation = locations.find(location => location.uuid === this.context.appAccountLocationUUID);
        if (matchingLocation && !selected_location) {
          selected_location = matchingLocation;
        }

        if (allJobtypes.length === 0) {
          errMsg = gettext("Du kan ikke stemple ind på en konto uden aktive jobtyper.");
        } else if (!this.state.selected_jobtype && allJobtypes.length === 1) {
          this.setState({selected_jobtype: allJobtypes[0]});
        }

        this.setState({
          allJobtypes: allJobtypes,
          employeeJobtypes: employeeJobtypes,
          locations: locations,
          selected_location: selected_location,
          data_loaded: true,
          errMsg: errMsg
        });

      } else if (jqXHR.statusCode === 401) {
        this.context.punchclockRaiseInvalidUserToken({msg: gettext("Ugyldig pinkode, log venligst ind igen.")});
      } else {
        var errMsg = jqXHR.body.detail ? jqXHR.body.detail : gettext("Der skete en fejl. Prøv venligst igen.");
        this.setState({errMsg: errMsg});
      }
    }.bind(this);

    Api.Dispatcher({endPoint: 'punchclock/worklogs/create/', method: 'get', headers: {'Authorization': "token " + this.context.punchclockUserToken}}, apiDispatchCallback)
  },

  componentDidMount: function() {
    this.loadDataFromServer();
    var intervalId = setInterval(this.loadDataFromServer, 15000);
    this.setState({intervalId: intervalId});
  },

  componentWillUnmount: function() {
    if (this.state.intervalId) {
      clearInterval(this.state.intervalId);
    }
  },

  submitClockInData: function (jobtype_id, location_uuid,end_time) {
    var apiDispatchCallback = function(jqXHR) {
      if (jqXHR.statusCode === 200)  {
        this.context.appLoadTerminal({confirmation: gettext('Du er nu stemplet ind, god arbejdslyst.')})
      }
      else if ([401].indexOf(jqXHR.statusCode) >= 0) {
        this.context.punchclockRaiseInvalidUserToken({msg: gettext("Ugyldig pinkode, log venligst ind igen.")});
      }
      else {
        var errMsg = jqXHR.body.detail ? jqXHR.body.detail : gettext("Der skete en fejl. Prøv venligst igen.");
        this.setState({errMsg: errMsg});
      }
    }.bind(this);

    var data = {jobtype_id: jobtype_id, location_uuid: location_uuid, end_time: end_time};
    console.log("submitClockInData", data);
    Api.Dispatcher({endPoint: 'punchclock/worklogs/create/', method: 'post', data: data, headers: {'Authorization': "token " + this.context.punchclockUserToken}}, apiDispatchCallback)
  },

  componentDidUpdate: function(prevProps, prevState){
    if (prevState.errMsg) {
      this.setState({errMsg: ""});
    }
  },

  // HANDLE EVENTS
  handleJobtypeSelected: function(jobtype) {
    this.setState({selected_jobtype: jobtype});
  },
  handleLocationSelected: function(location) {
    this.setState({selected_location: location});
  },
  handleEndtimeSelected: function(value, callback) {
    var valid_formats = ["HH:mm", "HHmm", "HH"];
    var valid_input = moment(value, valid_formats, true).isValid();
    if (valid_input) {
      this.setState({selected_endtime: value, errMsg: ""});
    } else {
      this.setState({errMsg: gettext("Forkert tidsformat. Brug HH:MM")});
      callback(false);
    }
  },
  handleClockIn: function() {
    console.log("handleClockIn;")
    var selected_location_uuid = this.state.selected_location.uuid;
    var selected_jobtype_id = this.state.selected_jobtype.id
    var selected_endtime = this.state.selected_endtime;
    var endtimeRequired = !!(this.context.appSettings.endTimeRequired === 'required');
    console.log("endtimeRequired:", endtimeRequired);
    if (!selected_location_uuid) {
      this.setState({errMsg: gettext("vælg lokation!")});
    }
    else if (!selected_jobtype_id) {
      this.setState({errMsg: gettext("vælg jobtype!")});
    }
    else if (!selected_endtime && endtimeRequired) {
      this.setState({errMsg: gettext("vælg tid!")});
    }
    this.submitClockInData(selected_jobtype_id, selected_location_uuid, selected_endtime);
  },

  // RENDER METHODS
  getScene: function() {
    var data_loaded = this.state.data_loaded;
    var selected_jobtype = this.state.selected_jobtype;
    var selected_location = this.state.selected_location;
    var selected_endtime = this.state.selected_endtime;
    var endtimeRequired = this.context.appSettings.endTimeRequired;
    var scene = 'loading';
    if (data_loaded) {
      if (!selected_jobtype) {
        scene = 'jobtype';
      } else if (!selected_location) {
        scene = 'location';
      } else if (endtimeRequired !== 'off' && !selected_endtime) {
        scene = 'endtime';
      } else {
        // This covers both cases where endtimeRequired is 'off',
        // or endtimeRequired is not 'off' but selected_endtime is truthy
        scene = 'confirm';
      }
  }
    return scene;
  },
  renderScene: function () {
    // scenes: jobtype > (location > end_time >) confirm
    var rendered_scene = function () {
      var scene = this.getScene();
      if (scene === 'jobtype') {
        return (
          <div>
            <ClockInSceneJobtype allJobtypes={this.state.allJobtypes} employeeJobtypes={this.state.employeeJobtypes} handleJobtypeSelected={this.handleJobtypeSelected}/>
          </div>
        );
      }
      else if (scene === 'location') {
        return (
          <div>
            <ClockInSceneLocation locations={this.state.locations} handleLocationSelected={this.handleLocationSelected}/>
          </div>
        );
      }
      else if (scene === 'endtime') {
        return (
          <div>
            <ClockInSceneEndTime handleEndtimeSelected={this.handleEndtimeSelected} />
          </div>
        );
      }
      else if (scene === 'confirm') {
        return (
          <div>
            <ClockInSceneConfirm handleConfirmation={this.handleClockIn} location={this.state.selected_location} jobtype={this.state.selected_jobtype} end_time={this.state.selected_endtime} locations={this.state.locations}/>
          </div>
        );
      }
      else {
        return (
          <div>{gettext("Vent venligst...")} </div>
        );
      }
    }.bind(this);
    return rendered_scene();
  },

  renderTitle: function() {
    var scene = this.getScene();
    if (scene === 'jobtype') {
      return gettext("Vælg jobtype for vagten");
    }
    else if (scene === 'location') {
      return gettext("Vælg hvilken lokation vagten tilhører");
    }
    else if (scene === 'endtime') {
      return gettext("Hvornår forventer du at vagten slutter?");
    }
    else if (scene === 'confirm') {
      return gettext("Vælg handling");
    } else {
      return gettext("Vent venligst...");
    }
  },

  render: function() {
    var title = this.renderTitle();
    var renderedScene = this.renderScene();
    if (this.state.errMsg) {
      alert(this.state.errMsg);
    }
    return (
      <div>
        <div className="titel">{title}</div>
        <div className="whiteBox">{renderedScene}</div>
      </div>
    );
  }
});



var ClockOutSceneBreakSelectionList = createReactClass({
  getInitialState: function(){
    // this.props er initial data sendt med fra 'parent'...
    // TODO: limit to shift length
    return {breakOptions: [0, 5, 10, 15,20, 25, 30, 35, 40, 45, 50, 55, 60]};
  },
  handleClick: function(value) {
    this.props.onBreakDurationSelected(value);
  },

  render: function() {
    var renderedBreakOptions = this.state.breakOptions.map(function(breakMins) {
      return (
        <div className="break smallList" onTouchTap={this.handleClick.bind(this, breakMins)} ref="breakMins" data-id={breakMins} key={breakMins} value={breakMins}>
          <p>{breakMins} {gettext("Minutter")}</p>
        </div>
      );
    }.bind(this)
    );
    return (
      <div>
        <div className="whiteConBox">
          {renderedBreakOptions}
        </div>
      </div>
    );
  }
});


var ClockOutSceneMessage = createReactClass({
  contextTypes: {
      // Get context from App component. Timezone for the account
      appSettings: PropTypes.object
  },

  getDefaultProps(){
    return {
      inputMessage: ""
    };
  },

  renderBreakDiv: function() {
    if (this.context.appSettings.breakSupport === 'after'){
      return (
        <div className="whiteConBox">
          <div onTouchTap={this.props.invokeBreakSelector} className="listItemHasValue">
            <div className="flex">
              <p>{gettext("Angiv afholdt pause")}</p>
              <p className="sub">{gettext("Angiv hvis du har afholdt pause under vagten")}</p>
            </div>
            <p className="valueLabel">{this.props.defaultBreakMins ? this.props.defaultBreakMins + gettext(" minutter") : <span className="dropdownIcon"></span>}</p>
          </div>
        </div>
      );
    } else {
      return;
    }
  },

  inputTrigger: function() {
    this.props.messageInputUpdate(this.refs.inputTrigger.value);
  },

  renderMessageDiv: function() {
    return (
      <div className="commentWrap">
        <p className="textareaLabel">{gettext("Vedhæftet kommentar til vagten")}</p>
        <div className="sub">
            <textarea ref='inputTrigger'
              placeholder={gettext('Skriv din kommentar her, hvis nødvendigt...')}
              onChange={this.inputTrigger}
              maxLength={500}
              value={this.props.inputMessage}
              className="textAreaList"
            ></textarea>
        </div>
      </div>
    );
  },
  render: function() {
    let breakDiv = this.renderBreakDiv();
    let messageDiv = this.renderMessageDiv();
    let btnLabel = gettext("Stempel ud");
    return (
      <div className="confirm">
        {breakDiv}
        <div className="whiteConBox">
          {messageDiv}
          <PunchClockButton
            action={this.props.submitClockOut}
            btnID={"clockOut"}
            colour={"red"}
            label={btnLabel}
            handleBtnClick={this.handleBtnClick}
          />
        </div>
      </div>
    );
  }
});

var ClockOutView = createReactClass({
  /*
   * Component for the form/view when punching in for a new unschedule shift.
   * fetches form data from the backend (jobtypes, locations etc.)
   * also handles submit and error handling through the API.
   * */
  // INITIAL STUFF
  contextTypes: {
    // Get context from App component. Timezone for the account
    punchclockUserToken: PropTypes.string,
    punchclockRaiseInvalidUserToken: PropTypes.func,
    appLoadTerminal: PropTypes.func
  },
  getInitialState() {
    var defaultBrakeMins = this.props.shift.pause_mins || 0;
    return {
      inputMessage: "",
      inputBreakMins: null,
      defaultBrakeMins: defaultBrakeMins,
      breakInputOn: true,
      errMsg: "",
      scene: "message"
    };
  },
  componentDidMount: function(){

  },
  handleBreakDurationSelected: function (value) {
    this.setState({inputBreakMins: value, scene: "message"});
  },
  invokeBreakSelector: function() {
    this.setState({scene: 'break'});
  },
  updateMessageInput: function(value) {
    this.setState({inputMessage: value})
  },
  submitClockOut: function(buttonClicked) {
    //e.preventDefault();

    var apiDispatchCallback = function(jqXHR) {
      if (jqXHR.statusCode === 200) {
        this.context.appLoadTerminal({confirmation: gettext('Du er nu stemplet ud. Tak for i dag!')});
      }
      else if ([401].indexOf(jqXHR.statusCode) >= 0) {
        this.context.punchclockRaiseInvalidUserToken({msg: gettext("Ugyldig pinkode, log venligst ind igen.")});
      }
      else {
        var errMsg = jqXHR.body.detail ? jqXHR.body.detail : gettext("Der skete en fejl. Prøv venligst igen.");
        this.setState({errMsg: errMsg});
        buttonClicked.setState({loading: false});
      }
    }.bind(this);

    var end_point = "punchclock/worklogs/" + this.props.shift.id + "/clock_out/";
    var clock_out_data = {inputBreakMins: this.state.inputBreakMins, inputMessage: this.state.inputMessage};
    Api.Dispatcher({endPoint: end_point, method: 'post', data: clock_out_data, headers: {'Authorization': "token " + this.context.punchclockUserToken}}, apiDispatchCallback)

  },

  // RENDER METHODS
  renderScene: function () {
    var scene = this.state.scene;
    let breakMins = this.state.inputBreakMins ? this.state.inputBreakMins : this.state.defaultBreakMins;
    if (scene === "message") {
      return (
         <div>
          <ClockOutSceneMessage submitClockOut={this.submitClockOut} breakInputOn={this.state.breakInputOn} defaultBreakMins={breakMins} inputMessage={this.state.inputMessage} invokeBreakSelector={this.invokeBreakSelector} messageInputUpdate={this.updateMessageInput}/>
         </div>
      );
    }
    else if (scene === "break") {
       return (
         <div>
          <ClockOutSceneBreakSelectionList onBreakDurationSelected={this.handleBreakDurationSelected} />
         </div>
       );
    }
  },
  render: function() {
    var renderedScene = this.renderScene();
    var titel = gettext("Angiv pause og evt. besked");
    if (this.state.errMsg) {
      alert(this.state.errMsg);
    }
    return (
      <div>
        <div className="titel">
          {titel}
        </div>
        <div className="whiteBox">
          {renderedScene}
        </div>
      </div>
    );
  }
});


var PunchClock = createReactClass({
  // Initial/Context STUFF:
  contextTypes: {
      // Get context from App component. Timezone for the account
      appAccountToken: PropTypes.string,
      appRaiseInvalidAccountToken: PropTypes.func,
      appLoadTerminal: PropTypes.func,
      appSettings: PropTypes.object,
  },
  childContextTypes: {
      // Get context from App component. Timezone for the account
      punchclockUserToken: PropTypes.string,
      punchclockEmployee:  PropTypes.object,
      punchclockInvokeUnscheduledClockIn: PropTypes.func,
      punchclockInvokeClockOut: PropTypes.func,
      punchclockRaiseInvalidUserToken: PropTypes.func
  },
  getChildContext: function() {
    var context = {
      punchclockUserToken: this.state.userToken,
      punchclockEmployee: this.props.employee,
      punchclockInvokeClockOut: this.invokeClockOut,
      punchclockInvokeUnscheduledClockIn: this.invokeUnscheduledClockIn,
      punchclockRaiseInvalidUserToken: this.handleInvalidUserToken
    };
    return context;
  },
  getInitialState: function() {
    return {
        action: null,
        userToken: "",
        actionShift: null,
        pinCodeRequested: null
    };
  },

  // Actions
  invokeClockOut: function(shift) {
      this.setState({action: "clockOut", actionShift: shift});

  },
  invokeUnscheduledClockIn: function() {
      this.setState({action: "unscheduledClockIn"});

  },
  cancelAction: function() {
    // cancel current action and return to the main PunchClock View.
      this.setState({action: null});

  },

  componentDidMount: function () {
    history.pushState({ page: 1 }, "title 1", window.location.pathname + window.location.search);
    window.onpopstate = function(event){
      this.context.appLoadTerminal({});
    }.bind(this)
  },


  // CALLBACKS:
  handleUserToken: function(value) {
    var user_token = "";
      if (value) {
        user_token = value;
      }
    this.setState({userToken: user_token});
  },
  handleInvalidUserToken: function() {
    this.setState({userToken: ""});
  },
  requestPin: function () {
  if (this.state.pinCodeRequested) {
      alert(gettext('Hav venligst tålmodighed. Din pinkode er afsendt... '));
    } else {
      this.setState({pinCodeRequested: true});

      var data = {membership_id: this.props.employee.id};
      var apiDispatchCallback = function(jqXHR) {
        if (jqXHR.statusCode === 200 && jqXHR.body.detail) {
          alert(jqXHR.body.detail);
        }
        else if (jqXHR.statusCode === 400) {
          // Bad request
          alert(jqXHR.body.errMsg ? jqXHR.body.errMsg : gettext("Der skete en fejl. Prøv venligst igen."));
        }
        else if (jqXHR.statusCode === 401) {
          // Unauthorized... wrong token!
          this.context.appRaiseInvalidAccountToken({msg: gettext("Ugyldig pinkode, log venligst ind igen.")});
        }
        else {
          // Server error???
          this.setState({pinCodeRequested: false});
          var errMsg = jqXHR.body.errMsg ? jqXHR.body.errMsg : gettext("Der skete en fejl. Prøv venligst igen.");
          alert(errMsg);
        }
      }.bind(this);
      // Submit call to API. will call callback when done.
      Api.Dispatcher({endPoint: "punchclock/"+this.props.employee.id + "/request_pin_code/", method: 'post', datatype: 'json', headers: {'Authorization': "token " + this.context.appAccountToken}}, apiDispatchCallback)
    }
  },

  onSubmitPin: function(pincode) {
    var data = {membership_id: this.props.employee.id, pincode: pincode.join("")};
    var apiDispatchCallback = function(jqXHR) {
      if (jqXHR.statusCode === 200 && jqXHR.body.token) {
        var token = jqXHR.body.token;
        this.handleUserToken(token);
      }
      else if (jqXHR.statusCode === 400) {
        // Bad request
        alert(jqXHR.body.errMsg ? jqXHR.body.errMsg : gettext("Der skete en fejl. Prøv venligst igen."));
      }
      else if (jqXHR.statusCode === 401) {
        // Unauthorized... wrong token!
        this.context.appRaiseInvalidAccountToken({msg: gettext("Ugyldig pinkode, log venligst ind igen.")});
      }
      else {
        alert(jqXHR.body.errMsg ? jqXHR.body.errMsg : gettext("Der skete en fejl. Prøv venligst igen."));
      }
    }.bind(this);
    // Submit call to API. will call callback when done.
    Api.Dispatcher({endPoint: 'auth/clock_user_token/', method: 'post', data: data, datatype: 'json', headers: {'Authorization': "token " + this.context.appAccountToken}}, apiDispatchCallback)
  },

  renderMainContent: function() {
    /*
    * Kan renderer
    * - UnscheduledClockInView
    * - ShiftsView (default)
    * - LoginView
    * */
    var userToken = this.state.userToken;
    var action = this.state.action;
    var label;
    if (userToken) {
      if (action === 'unscheduledClockIn') {
        return <UnscheduledClockInView/>
      }
      else if (action === 'clockOut') {
        return <ClockOutView shift={this.state.actionShift}/>
      }
      else {
        label = gettext("Vælg en vagt");
        return <ShiftsView label={label}/>
      }
    } else {
      var fmts = gettext("Indtast pinkode for %(name)s");
      label = interpolate(fmts, {name: this.props.employee.full_name}, true);
      return <LoginView onSubmitPin={this.onSubmitPin} label={label} requestPin={this.requestPin}/>
    }
  },
  render: function() {
    var mainContentRendered = this.renderMainContent();
    return (
      <div className="contentWrap">
        <div className="actionWrap">
          <div className="col-xs-12  choosen-avatar">
            <Avatar member={this.props.employee} size="large"/>
          </div>
          <div className='PunchClockWrapper col-xs-12 off-xs-0 col-sm-10 off-sm-1 col-md-6 off-md-3 col-lg-4 off-lg-4'>
            {mainContentRendered}
          </div>
          <div>
            <span onTouchTap={this.context.appLoadTerminal} className="startOver">{gettext("Start forfra")}</span>
          </div>
        </div>
      </div>
    );
  }
});



module.exports = {
  Main: PunchClock
};
